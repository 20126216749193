import React from 'react';
import { Link as BrowserLink } from 'react-router-dom';

import ReactGA from 'react-ga4';

import {
	Button,
	FormControl,
	FormControlLabel,
	FormLabel,
	InputLabel,
	LinearProgress,
	Link,
	MenuItem,
	Paper,
	Radio,
	RadioGroup,
	Select,
	Stack,
	TextField,
	Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { Auth } from 'aws-amplify';
import LoginWrapper from './LoginWrapper';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { AppContext } from '../utilities/StateProvider';
import { UserAttributesI } from '../utilities/store';
import CustomAlert, { CustomAlertPropsI } from '../utilities/CustomAlert';
import Logo from '../utilities/Logo';
import { Link as RouterLink } from 'react-router-dom';
import SSOLinks from './SSOLinks';
import { htmlValidationExpression } from '../utilities/helpers';

interface SignupFormI {
	username: string;
	password: string;
	given_name: string;
	family_name: string;
	accountType: 'personal' | 'business';
	company_name: string;
	company_website: string;
	attributionOption: string;
	attributionOther: string;
	attribution_id: string;
}

const attributionOptions = [
	'Search engine (e.g. Google)',
	'Comparison article',
	'YouTube',
	'Agora',
	'Vonage',
	'AWS',
	'Word of mouth or recommendation',
	'Other',
].map((option) => ({
	text: option,
	value: option,
}));

const Signup = () => {
	const [loading, setLoading] = React.useState(false);
	const [alert, setAlert] = React.useState<CustomAlertPropsI>();
	const [showVerifyEmailMessage, setShowVerifyEmailMessage] = React.useState(false);
	const [signupStage, setSignupStage] = React.useState<'username' | 'requiredInfo'>('username');
	const { state, dispatch } = React.useContext(AppContext);
	const {
		register,
		handleSubmit,
		formState: { errors },
		getFieldState,
		watch,
		control,
		trigger,
	} = useForm<SignupFormI>({
		defaultValues: {
			accountType: 'personal',
			attributionOption: '',
		},
	});

	const storedAttributionId = window.localStorage.getItem('attributionId');

	const handleSignUp: SubmitHandler<SignupFormI> = async (data: SignupFormI) => {
		setLoading(true);

		const { username, password, given_name, family_name, company_name, company_website } = data;

		const _storedAttributionId = window.localStorage.getItem('attributionId');
		let attributionId = '';

		if (!!_storedAttributionId) {
			attributionId = _storedAttributionId;
		} else if (data.attributionOption === 'Other') {
			attributionId = `Custom:${data.attributionOther}`;
		} else {
			attributionId = `Option:${data.attributionOption}`;
		}

		const attributes: UserAttributesI = {
			email: username,
			given_name,
			family_name,
			'custom:attribution_id': attributionId,
			'custom:company_name': company_name,
			'custom:company_website': company_website,
		};

		try {
			const { user } = await Auth.signUp({
				username,
				password,
				attributes,
			});
			dispatch({
				type: 'CHANGE_AUTH_STATE',
				payload: { currentUserAttributes: attributes, currentUser: user },
			});
			setShowVerifyEmailMessage(true);
			ReactGA.event('sign_up', {
				method: attributionId,
			});
		} catch (error: any) {
			setAlert({ severity: 'warning', message: error.message });
		} finally {
			setLoading(false);
		}
	};

	const handleContinue = async () => {
		await trigger('username');
		await trigger('password');
		const usernameState = getFieldState('username');
		const passwordState = getFieldState('password');
		if (!usernameState.error && !passwordState.error) {
			setSignupStage('requiredInfo');
		}
	};

	if (showVerifyEmailMessage) {
		return (
			<LoginWrapper>
				<Paper
					sx={{
						maxWidth: '500px',
						borderRadius: 2,
						overflow: 'hidden',
						padding: 4,
					}}
				>
					<Typography variant='h3' textAlign='center' gutterBottom>
						Verify your email
					</Typography>
					<Typography variant='body1' gutterBottom>
						To use DeepAR, click on the verification link in the email we sent to{' '}
						<strong>{state.appAuthState.currentUserAttributes?.email || 'your email'}</strong>. This helps keep your account secure.
					</Typography>
					<Button fullWidth variant='outlined' sx={{ my: 2 }} onClick={() => setShowVerifyEmailMessage(false)}>
						Back to sign up
					</Button>
				</Paper>
			</LoginWrapper>
		);
	}

	return (
		<LoginWrapper>
			<Box sx={{ height: '10px' }}>{loading && <LinearProgress />}</Box>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					padding: 4,
					textAlign: 'center',
				}}
			>
				<Stack sx={{ mw: '200px', textAlign: 'center', mb: 2 }} direction='column' alignItems='center'>
					<Link component={RouterLink} to='/' sx={{ display: 'flex', alignItems: 'center' }}>
						<Logo sx={{ mw: 3 }} />
					</Link>
					<img src='/login-icons.png' alt='Login icons showing DeepAR face filters' style={{ maxWidth: '100%', width: '240px', marginTop: '0.5rem' }} />
				</Stack>
				<Box component='form' onSubmit={handleSubmit(handleSignUp)} noValidate>
					{alert && <CustomAlert {...alert} />}
					{signupStage === 'username' && (
						<Box sx={{ textAlign: 'center' }}>
							<Typography variant='h3'>Create account</Typography>
							<Typography variant='body2' sx={{ mt: 1, opacity: 0.8 }}>
								By creating an account, you agree to our{' '}
								<Link href='/terms' target='_blank' rel='noopener noreferrer'>
									Terms of Service
								</Link>
								. For more information, please see our{' '}
								<Link href='/privacy' target='_blank' rel='noopener noreferrer'>
									Privacy Policy
								</Link>
								.
							</Typography>
							<SSOLinks />
							<TextField
								{...register('username', {
									required: {
										value: true,
										message: 'Please enter email address',
									},
									pattern: {
										value: htmlValidationExpression,
										message: 'Please enter a valid email address',
									},
								})}
								margin='normal'
								required
								fullWidth
								id='username'
								label='Email Address'
								name='username'
								autoComplete='email'
								autoFocus
								error={!!errors.username}
								helperText={errors?.username?.message}
							/>
							<TextField
								{...register('password', { required: true, minLength: 8 })}
								margin='normal'
								required
								fullWidth
								name='password'
								label='Password'
								type='password'
								id='password'
								error={!!errors.password}
								helperText={!!errors.password && 'Please enter a password that is 8 characters or longer'}
							/>
							<Button fullWidth variant='contained' sx={{ mt: 3, mb: 2 }} onClick={handleContinue}>
								Create account
							</Button>
						</Box>
					)}
					{signupStage === 'requiredInfo' && (
						<Box sx={{ textAlign: 'left' }}>
							<Box sx={{ textAlign: 'center' }}>
								<Typography variant='h3'>Just a few details</Typography>
							</Box>
							<Stack direction='row' spacing={1} sx={{ my: 2 }}>
								<TextField
									{...register('given_name', {
										required: {
											value: true,
											message: 'Please enter your first name',
										},
									})}
									required
									fullWidth
									name='given_name'
									label='First name'
									id='given_name'
									error={!!errors.given_name}
									helperText={errors?.given_name?.message}
									autoComplete='firstName'
								/>
								<TextField
									{...register('family_name', {
										required: {
											value: true,
											message: 'Please enter your last name',
										},
									})}
									required
									fullWidth
									name='family_name'
									label='Last name'
									id='family_name'
									error={!!errors.family_name}
									helperText={errors?.family_name?.message}
									autoComplete='lastName'
								/>
							</Stack>
							{!!storedAttributionId ? null : (
								<FormControl fullWidth margin='normal'>
									<InputLabel id='attributionOptionLabel'>How did you discover DeepAR?</InputLabel>
									<Controller
										control={control}
										name='attributionOption'
										render={({ field }) => (
											<Select {...field} label='How did you discover DeepAR?'>
												{attributionOptions.map((option, i) => (
													<MenuItem value={option.value} key={i}>
														{option.text}
													</MenuItem>
												))}
											</Select>
										)}
									/>
								</FormControl>
							)}
							{watch('attributionOption') === 'Other' && !storedAttributionId && (
								<TextField
									{...register('attributionOther', { required: watch('attributionOption') === 'Other' })}
									fullWidth
									name='attributionOther'
									label='Please specify'
									id='attributionOther'
									error={!!errors.attributionOther}
									helperText={!!errors.attributionOther && errors.attributionOther.message}
								/>
							)}
							<FormControl margin='normal' component='fieldset' fullWidth>
								<FormLabel component='legend'>Account type</FormLabel>
								<Controller
									control={control}
									name='accountType'
									render={({ field }) => (
										<RadioGroup {...field} row>
											<FormControlLabel value='personal' control={<Radio />} label='Personal' />
											<FormControlLabel value='business' control={<Radio />} label='Business' />
										</RadioGroup>
									)}
								/>
							</FormControl>
							{watch('accountType') === 'business' && (
								<Box>
									<TextField
										{...register('company_name')}
										margin='normal'
										fullWidth
										name='company_name'
										label='Company Name'
										id='company_name'
										error={!!errors.company_name}
										helperText={!!errors.company_name && errors.company_name.message}
									/>
									<TextField
										{...register('company_website')}
										margin='normal'
										fullWidth
										name='company_website'
										label='Company Website'
										id='company_website'
										error={!!errors.company_website}
										helperText={!!errors.company_website && errors.company_website.message}
									/>
								</Box>
							)}
							<Button type='submit' fullWidth variant='contained' sx={{ mt: 3, mb: 2 }}>
								Create account
							</Button>
							<Box sx={{ p: 1, textAlign: 'center' }}>
								<Link onClick={() => setSignupStage('username')} variant='body1' sx={{ margin: 'auto', cursor: 'pointer' }}>
									Back
								</Link>
							</Box>
						</Box>
					)}
					<Stack direction='row' spacing={1} justifyContent='center'>
						<Typography>Already have an account?</Typography>
						<Link component={BrowserLink} to='/login' variant='body1'>
							Log In
						</Link>
					</Stack>
				</Box>
			</Box>
		</LoginWrapper>
	);
};

export default Signup;
